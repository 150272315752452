import React from "react";
import { Link } from "react-router-dom";

const Deliveries = () => {
  return (
    <div className="flex flex-col mt-[8rem] md:mt-[20rem] px-8 md:px-0 w-full">
      <div className="max-w-[1080px] mx-auto grid grid-cols-1 md:grid-cols-2 items-center gap-[7rem] md:gap-[12.6rem]">
        <img
          src="/tracks.png"
          alt="delivery hero"
          className="w-full h-full order-2 md:order-1"
        />
        <div className="order-1 md:order-2">
          <h2 className="text-dark font-recoleta text-[5.6rem] font-medium leading-[7.6rem]">
            Track Deliveries With Our Shipment Tracking API
          </h2>
          <p className="text-[20px] leading-[3.2rem] font-rubik font-light text-accent mt-[1.6rem] mb-[2.4rem]">
            Receive real-time tracking and delivery notifications to know where
            your shipment is and when it will arrive. The tracking capabilities
            of our shipping API keep your business and customers up to date on
            the real-time whereabouts of orders.
          </p>
          <Link to="/request-demo">
            <button className="bg-accent text-white py-[1.6rem] px-[3rem] md:px-[4.8rem] rounded-[0.8rem] font-rubik text-[1.6rem] font-nrmal">
              Try Out Delivery Page
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Deliveries;

"use client";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useDispatch } from "react-redux";
import { getResources } from "../redux/features/resourceSlice";
import { useNavigate } from "react-router-dom";

const Tools = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getResources({ category: "SHIPMENT", currentPage: 1 }));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Delivery Page — Free Logistics Management Tools</title>
        <meta name="title" content="Free Logistics Management Tools" />
        <meta
          name="description"
          content="Easiest Way to Take Your Logistics Business Online"
        />
      </Helmet>

      <Navbar />
      <div className="relative mb-[12rem] flex flex-col items-center overflow-hidden">
        <div className="flex flex-col pt-[6.8rem] px-6 md:px-0">
          {/* <div className="flex flex-col pt-[9.8rem] px-6 md:px-0"> */}
          <div className="max-w-[850px] mx-auto text-center">
            <h2 className="font-recoleta text-[40px] font-[700] text-accent mb-[24px]">
              Free Logistics Management Tools
            </h2>
            <p className="text-[24px] font-rubik text-[#0D1326CC] font-[200] px-[8rem]">
              Access free tools to power your your logistics process and
              automate boring task.
            </p>
          </div>
          <div className="max-w-[1138px] mx-auto grid md:grid-cols-2 gap-x-[34px] gap-y-[40px] mt-[4rem] md:mt-[72px] px-[2rem] md:px-[8rem]">
            {/* <div className="max-w-[1138px] mx-auto grid grid-cols-2 md:grid-cols-5 gap-[3.2rem] mt-[8.5rem]"> */}
            {[{title: 'Price List Generator', content: 'Price List Generator is a handy tool that assist logistics businesses in creating detailed price lists. This tool empowers businesses to easily generate and share essential pricing information with clients.'}].map((tool) => (
              <div
                className="border border-[#27306633] bg-[#2730660D] border-opacity-50 py-[32px] px-[24px] rounded-xl cursor-pointer"
                onClick={() => navigate("/tools/price-list-generator")}
              >
                <h2 className="font-recoleta text-[24px] md:text-[2rem] font-[700] text-[#000000] mb-[8px]">
                  {tool.title}
                </h2>
                <p className="text-[16px] font-[300] font-rubik text-[#0D1326CC]">
                 {tool.content}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Tools;

import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

const subscribe = async () => {
  console.log(baseUrl)
  const res = await axios.get(`${baseUrl}/plan`)
  return res.data;
};

const resources = async (category, currentPage) => {
  console.log(category)
  const res = await axios.get(`${baseUrl}/academy?category=${category}&page=${currentPage}`)
  return res.data;
};



const subService = {
  subscribe,
  resources
}

export default subService
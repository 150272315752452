import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/authSlice"
import subscribeReducer from "./features/subscriptionSlice"
import resourceSlice from "./features/resourceSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        subscription: subscribeReducer,
        resources: resourceSlice
    }
})
import React from "react";
import ApiHero from "../components/api/ApiHero";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Launch from "../components/api/Launch";
import Showcase from "../components/api/Showcase";
import Compare from "../components/api/Compare";
import Deliveries from "../components/api/Deliveries";
import Partners from "../components/api/Partners";
import Integrate from "../components/api/Integrate";

const Api = () => {
  return (
    <>
      <Helmet>
        <title>
          Delivery Page — Register and Take Your Logistics Business Online
        </title>
        <meta name="title" content="Delivery Page" />
        <meta
          name="description"
          content="Easiest Way to Take Your Logistics Business Online"
        />
      </Helmet>
      <Navbar />
      <ApiHero />
      <Launch />
      <Showcase />
      <Compare />
      <Deliveries />
      <Partners />
      <Integrate />
      <Footer />
    </>
  );
};

export default Api;

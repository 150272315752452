import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import ReactGA from "react-ga4";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Cookie from "./pages/Cookie";
import NotFoundPage from "./pages/NotFoundPage";
import Tutorials from "./pages/Tutorials";
import Api from "./pages/Api";
import Tools from "./pages/Tools";
import PriceListGenerator from "./pages/tools/PriceListGenerator";

const App = () => {
  ReactGA.initialize("G-DKRLKSC9TB");

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/request-demo" element={<Register />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/cookie-policy" element={<Cookie />} />
        <Route path="/academy" element={<Tutorials />} />
        <Route path="/tools" element={<Tools />} />
        <Route
          path="/tools/price-list-generator"
          element={<PriceListGenerator />}
        />
        <Route path="/developers" element={<Api />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import subService from "../../services/subService";

const initialState = {
  videos: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getResources = createAsyncThunk(
  "resources",
  async ({ category, currentPage }, thunkAPI) => {
    try {
      return await subService.resources(category, currentPage);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue({
        message,
        errorData: error.response?.data?.errors || {},
      });
    }
  }
);

const resourceSlice = createSlice({
  name: "resource",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
    appendVideos: (state, action) => {
      state.videos.data = [...state.videos.data, ...action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResources.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResources.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.videos = action.payload
      })
      .addCase(getResources.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload.message;
      });
  },
});

export const { reset, appendVideos } = resourceSlice.actions;

export default resourceSlice.reducer;

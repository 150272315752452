"use client";
import React, { useState, useEffect } from "react";
import Academy from "../components/tutorials/Academy";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Videos from "../components/tutorials/Videos";
import { useDispatch } from "react-redux";
import { getResources } from "../redux/features/resourceSlice";

const Tutorials = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedType, setSelectedType] = useState("SHIPMENT");
  const [category, setCategory] = useState("SHIPMENT");

  useEffect(() => {
    dispatch(getResources({ category: "SHIPMENT", currentPage: 1 }));
  }, [dispatch]);

  const handleShipment = () => {
    setSelectedType("SHIPMENT");
    setCategory("SHIPMENT");
    dispatch(getResources({ category: "SHIPMENT", currentPage: 1 }));
    setCurrentPage(1);
  };
  const handleRider = () => {
    setSelectedType("RIDER");
    setCategory("RIDER");
    dispatch(getResources({ category: "RIDER", currentPage: 1 }));
    setCurrentPage(1);
  };

  const handleWebsite = () => {
    setSelectedType("WEBSITE_DESIGN");
    setCategory("WEBSITE_DESIGN");
    dispatch(getResources({ category: "WEBSITE_DESIGN", currentPage: 1 }));
    setCurrentPage(1);
  };

  const handlePricing = () => {
    setSelectedType("PRICING_RULE");
    setCategory("PRICING_RULE");
    setCurrentPage(1);
    dispatch(getResources({ category: "PRICING_RULE", currentPage: 1 }));
  };

  const handleFleet = () => {
    setSelectedType("FLEET_MGT");
    setCategory("FLEET_MGT");
    setCurrentPage(1);
    dispatch(getResources({ category: "FLEET_MGT", currentPage: 1 }));
  };
  return (
    <>
      <Helmet>
        <title>
          Delivery Page — Easiest Way to Take Your Logistics Business Online
        </title>
        <meta name="title" content="Delivery Page" />
        <meta
          name="description"
          content="Easiest Way to Take Your Logistics Business Online"
        />
      </Helmet>

      <Navbar />
      <div className="relative flex flex-col items-center overflow-hidden">
        <Academy
          handleShipment={handleShipment}
          handleRider={handleRider}
          handlePricing={handlePricing}
          handleWebsite={handleWebsite}
          handleFleet={handleFleet}
          selectedType={selectedType}
        />
        <Videos
          category={category}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <Footer />
    </>
  );
};

export default Tutorials;

import React from "react";
import { AiOutlinePlus } from "react-icons/ai";

const ViewMoreButton = ({ handleMore }) => {
  return (
    <div className="flex justify-center mt-[4.8rem]">
      <button
        className="bg-[#f2f2f2] font-medium text-[#6b6b6b] text-[1.3rem] rounded-[0.8rem] py-[1rem] px-[2rem] flex items-center gap-[0.8rem]"
        onClick={handleMore}
      >
        <AiOutlinePlus color="#6b6b6b" size={20} /> View More
      </button>
    </div>
  );
};

export default ViewMoreButton;

import React from "react";

const Partners = () => {
  return (
    <div className="flex flex-col mt-[8rem] md:mt-[20rem] px-8 md:px-0 w-full">
      <div className="max-w-[1080px] mx-auto grid grid-cols-1 md:grid-cols-2 items-center gap-[7rem] md:gap-[10.8rem]">
        <div>
          <h2 className="text-dark font-recoleta text-[5.6rem] font-medium leading-[7.6rem]">
            25+ Credible Delivery Partners
          </h2>
          <p className="text-[20px] leading-[3.2rem] font-rubik font-light text-dark mt-[3.2rem] mb-[4.1rem]">
            Connect with local, international, and regional delivery service
            providers.
          </p>
        </div>
        <div className="grid grid-cols-3 gap-[5rem]">
          <img
            src="/ups.png"
            alt="ups"
            className="w-[10rem] h-[10rem] object-cover"
          />
          <img
            src="/fedex.png"
            alt="fedex"
            className="w-[10rem] h-[10rem] object-cover"
          />
          <img
            src="/kwik.png"
            alt="kwik"
            className="w-[10rem] h-[10rem] object-cover"
          />
          <img
            src="/dhl.png"
            alt="ups"
            className="w-[10rem] h-[10rem] object-cover"
          />
          <img
            src="/d.png"
            alt="fedex"
            className="w-[10rem] h-[10rem] object-cover"
          />
          <img
            src="/abc.png"
            alt="kwik"
            className="w-[10rem] h-[10rem] object-cover"
          />
          <img
            src="/g.png"
            alt="ups"
            className="w-[10rem] h-[10rem] object-cover"
          />
          <img
            src="/topship.png"
            alt="topship"
            className="w-[10rem] h-[10rem] object-cover"
          />
          <img
            src="/gig.png"
            alt="gig"
            className="w-[10rem] h-[10rem] object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Partners;

import React from "react";

const Showcase = () => {
  return (
    <div className="pt-[8.8rem] w-full px-8 md:px-0">
      <div className="mx-auto max-w-[1080px] w-full gap-[1.6rem] items-center grid grid-cols-2 md:grid-cols-4">
        <div className="flex flex-col items-center border border-[#E2E8F0] shadow-sm pt-[3.6rem] px-[3.7rem] pb-[2.8rem] gap-[0.8rem] rounded-[0.8rem]">
          <div className="flex align-center justify-center bg-lightBlue text-white p-[0.8rem] rounded-[0.8rem]">
            <img
              src="/deliveryone.svg"
              className="w-[4rem] h-[4rem]"
              alt="delivery one"
            />
          </div>
          <h2 className="text-[2rem] text-center text-blue font-recoleta font-normal mt-[0.8rem]">
            On Demand Delivery
          </h2>
        </div>
        <div className="flex flex-col items-center border border-[#E2E8F0] shadow-sm pt-[3.6rem] px-[3.7rem] pb-[2.8rem] gap-[0.8rem] rounded-[0.8rem]">
          <div className="flex align-center justify-center bg-lightBlue text-white p-[0.8rem] rounded-[0.8rem]">
            <img
              src="/courier.svg"
              className="w-[4rem] h-[4rem]"
              alt="delivery one"
            />
          </div>
          <h2 className="text-[2rem] text-center text-blue font-recoleta font-normal mt-[0.8rem]">
            Courier Delivery
          </h2>
        </div>
        <div className="flex flex-col items-center border border-[#E2E8F0] shadow-sm pt-[3.6rem] px-[3.7rem] pb-[2.8rem] gap-[0.8rem] rounded-[0.8rem]">
          <div className="flex align-center justify-center bg-lightBlue text-white p-[0.8rem] rounded-[0.8rem]">
            <img
              src="/commerce.svg"
              className="w-[4rem] h-[4rem]"
              alt="delivery one"
            />
          </div>
          <h2 className="text-[2rem] text-center text-blue font-recoleta font-normal mt-[0.8rem]">
            E-commerce Delivery
          </h2>
        </div>
        <div className="flex flex-col items-center border border-[#E2E8F0] shadow-sm pt-[3.6rem] px-[3.7rem] pb-[2.8rem] gap-[0.8rem] rounded-[0.8rem]">
          <div className="flex align-center justify-center bg-lightBlue text-white p-[0.8rem] rounded-[0.8rem]">
            <img
              src="/food.svg"
              className="w-[4rem] h-[4rem]"
              alt="delivery one"
            />
          </div>
          <h2 className="text-[2rem] text-center text-blue font-recoleta font-normal mt-[0.8rem]">
            Food Delivery
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Showcase;

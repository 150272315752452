import axios from "axios";

const register = async (userData) => {
  const res = await axios.post('https://vitamin.deliverypage.africa/api/waitlist', userData)
  if(res.data) {
    localStorage.setItem('user', JSON.stringify(res.data))
  }
  console.log(res.data)
  return res.data;
};


const authService = {
  register
}

export default authService
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

const getStates = async () => {
  console.log(baseUrl);
  const res = await axios.get(
    `${baseUrl}/9c0aafcb-a91a-44b1-8ce6-ce21fa176a69/misc/state`
  );
  return res.data;
};

const getCities = async () => {
  const res = await axios.get(
    `${baseUrl}/9c0aafcb-a91a-44b1-8ce6-ce21fa176a69/misc/city`
  );
  //   const res = await axios.get(`${baseUrl}/9c0aafcb-a91a-44b1-8ce6-ce21fa176a69/misc/city?state_id=${state_id}`)
  return res.data;
};

const uploadPriceList = async (data) => {
  try {
    const res = await axios.post(`${baseUrl}/tool/price_generator`, data);
    return res.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export { getStates, getCities, uploadPriceList };

import React from "react";

const Integrate = () => {
  return (
    <div className="flex flex-col mt-[10rem] md:mt-[20rem] mb-[18.4rem] px-8 md:px-0 w-full">
      <div className="max-w-[1080px] mx-auto grid grid-cols-1 md:grid-cols-2 items-center gap-[7rem] md:gap-[12.6rem]">
        <img
          src="/easy.png"
          alt="delivery hero"
          className="w-full h-full order-2 md:order-1"
        />
        <div className="order-1 md:order-2">
          <h2 className="text-dark font-recoleta text-[5.6rem] font-medium leading-[7.6rem]">
            Easy To Integrate APIs
          </h2>
          <p className="text-[20px] leading-[3.2rem] font-rubik font-light text-dark mt-[3.2rem] mb-[4.1rem]">
            Documentation, user instructions, and examples are all provided. We
            built the Delivery Page API endpoints and responses to be simple and
            easy to implement.
          </p>
          <div className="">
            <button className="bg-accent text-white py-[1.6rem] px-[3rem] md:px-[4.8rem] rounded-[0.8rem] font-rubik text-[1.6rem] font-nrmal">
              Explore Documentation
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integrate;

import React from "react";

const ApiHero = () => {
  return (
    <div className="flex flex-col pt-[12.3rem] pb-[15.2rem] px-8 md:px-0 w-full">
      <div className="max-w-[1080px] mx-auto grid grid-cols-1 md:grid-cols-2 items-center gap-[7rem] md:gap-[9.8rem]">
        <div>
          <h2 className="text-accent font-recoleta text-[5.6rem] font-semibold leading-[7.6rem]">
            All your Delivery Needs In a Single API.
          </h2>
          <p className="text-[20px] leading-[3.2rem] font-rubik font-light text-dark mt-[3.2rem] mb-[4.1rem]">
            Delivery Page provides you with advanced delivery APIs for you to
            connect any platform to multiple delivery and shipping service
            providers.
          </p>
          <div className="flex gap-[1.6rem] items-center">
            <button className="bg-accent text-white py-[1.6rem] px-[3rem] md:px-[4.8rem] rounded-[0.8rem] font-rubik text-[1.6rem] font-nrmal">
              Get Started
            </button>
            <button className="border border-[#4360BB] text-[#4360BB] bg-[#EDF0F9] py-[1.6rem] px-[3rem] md:px-[4.8rem] text-[1.6rem] font-normal rounded-[0.8rem] font-rubik">
              View Documentation
            </button>
          </div>
        </div>
        <img src="/heroapi.png" alt="api hero" className="w-full h-full" />
      </div>
    </div>
  );
};

export default ApiHero;

import React from 'react'
import ErrorImage from "../assets/error.jpg"

const NotFound = () => {
  return (
    <div className='flex flex-col items-center pb-[10rem] mt-[2rem] px-[4rem] '>
        <img src={ErrorImage} className='w-[700px] h-[600px] object-contain' />
        <p className='text-[2rem] font-rubik text-blue font-medium leading-[3.2rem]'>You didn't break the internet, but we can't find what you are looking for.</p>
    </div>
  )
}

export default NotFound
import { useDispatch, useSelector } from "react-redux";
import ViewMoreButton from "./ViewMoreButton";
import VideoModal from "./VideoModal";
import { useEffect, useState } from "react";
import Loader from "../Loader";
import { appendVideos } from "../../redux/features/resourceSlice";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Videos = ({ category, currentPage, setCurrentPage }) => {
  const dispatch = useDispatch();
  const videos = useSelector((state) => state.resources.videos.data);
  const isLoading = useSelector((state) => state.resources.isLoading);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [isVideoLoading, setIsVideoLoading] = useState(false);

  useEffect(() => {
    const fetchVideos = async () => {
      if (currentPage <= 1) return;
      try {
        const res = await axios.get(
          `${baseUrl}/academy?category=${category}&page=${currentPage}`
        );
        dispatch(appendVideos(res.data.data));
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };
    fetchVideos();
  }, [currentPage]);

  console.log(category);

  useEffect(() => {
    if (currentVideo) {
      setIsVideoLoading(false);
      // setTimeout(() => {
      //   setIsVideoLoading(false);
      // }, 3000);
    }
  }, [currentVideo]);

  const videosPerPage = 9;

  const indexOfLastVideo = currentPage * videosPerPage;
  // const indexOfFirstVideo = indexOfLastVideo - videosPerPage;

  const latestVideo = videos?.slice(0, indexOfLastVideo);
  console.log(currentVideo);

  const handlePlayClick = (video) => {
    setIsVideoLoading(true);
    setCurrentVideo(video);
  };

  const handleMore = async () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleClose = () => {
    setCurrentVideo(null);
    setIsVideoLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col mb-[18rem] mt-[2rem] px-8 md:px-0">
      <div className="max-w-[1138px] mx-auto grid grid-cols-1 md:grid-cols-3 gap-[3.2rem] mt-[8.5rem]">
        {!isLoading &&
          latestVideo?.map((video) => (
            <div
              className="w-full rounded-[1.2rem] border-opacity-20 border border-accent px-[1.6rem] pb-[5.9rem] pt-[1.6rem]"
              key={video.id}
            >
              <div className="w-full bg-accent flex items-center justify-center h-[17.3rem] rounded-[0.8rem]">
                <img
                  src="/play.svg"
                  alt="play"
                  className="cursor-pointer w-[7.2rem] h-[7.2rem]"
                  onClick={() => handlePlayClick(video)}
                />
                {currentVideo && (
                  <VideoModal
                    video={currentVideo}
                    onClose={handleClose}
                    isVideoLoading={isVideoLoading}
                    setIsVideoLoading={setIsVideoLoading}
                  />
                )}
              </div>
              <h2 className="text-[2rem] text-accent font-semibold font-recoleta mt-[1.6rem]">
                {video.title}
              </h2>
              <p className="text-[1.6rem] text-accent font-rubik font-light mt-[1rem]">
                {video.short_description}.
              </p>
            </div>
          ))}
      </div>
      <ViewMoreButton handleMore={handleMore} />
    </div>
  );
};

export default Videos;

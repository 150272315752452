import React from "react";

const Launch = () => {
  return (
    <div className="flex flex-col w-full">
      <div className="bg-[#F8F8F8] max-w-[1280px] py-[6.4rem] pb-[8rem] mx-auto w-full flex flex-col items-center rounded-[2.4rem] px-8 md:px-0">
        <div className="max-w-[750px] mx-auto">
          <h2 className="text-dark hidden md:block text-[5.6rem] text-center leading-[7.6rem] font-recoleta font-medium">
            Launch Your Delivery<br></br> Product Faster
          </h2>
          <h2 className="text-dark block md:hidden text-[5.6rem] text-center leading-[7.6rem] font-recoleta font-medium">
            Launch Your Delivery<br></br> Product Faster
          </h2>
          <p className="text-[2rem] font-rubik leading-[3.2rem] font-light mt-[1.6rem] mb-[6.4rem] text-center">
            We’ve combined all the delivery partners you would need to run a
            successful delivery platform, and removed the complexity of
            integrating with them differently.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-0 mt-[6.4rem] max-w-[990px] mx-auto w-full items-center">
          <div className="flex flex-col gap-[3rem]">
            <div className="bg-white rounded-[1rem] p-[0.8rem] gap-[0.8rem] flex items-center">
              <img
                src="/topship.png"
                alt="topship"
                className="w-[4rem] h-[4rem] object-cover"
              />
              <p className="font-rubik text-dark text-[1.6rem]">
                United Parcel Service
              </p>
            </div>
            <div className="bg-white rounded-[1rem] p-[0.8rem] gap-[0.8rem] flex items-center">
              <img
                src="/fedex.png"
                alt="topship"
                className="w-[4rem] h-[4rem] object-cover"
              />
              <p className="font-rubik text-dark text-[1.6rem]">
                Federal Express
              </p>
            </div>
            <div className="bg-white rounded-[1rem] p-[0.8rem] gap-[0.8rem] flex items-center">
              <img
                src="/kwik.png"
                alt="topship"
                className="w-[4rem] h-[4rem] object-cover"
              />
              <p className="font-rubik text-dark text-[1.6rem]">
                Kwik Delivery
              </p>
            </div>
            <div className="bg-white rounded-[1rem] p-[0.8rem] gap-[0.8rem] flex items-center">
              <img
                src="/dhl.png"
                alt="topship"
                className="w-[4rem] h-[4rem] object-cover"
              />
              <p className="font-rubik text-dark text-[1.6rem]">
                Dalsey Hillblom Lynn
              </p>
            </div>
            <div className="bg-white rounded-[1rem] p-[0.8rem] gap-[0.8rem] flex items-center">
              <img
                src="/g.png"
                alt="topship"
                className="w-[4rem] h-[4rem] object-cover"
              />
              <p className="font-rubik text-dark text-[1.6rem]">
                Gokada Delivery
              </p>
            </div>
          </div>
          <img
            src="/dp.png"
            alt="dp logo"
            className="object-cover w-full h-[35rem] z-50"
          />
          <div className="flex flex-col gap-[3rem]">
            <div className="bg-white rounded-[1rem] p-[0.8rem] gap-[0.8rem] flex items-center">
              <img
                src="/topship.png"
                alt="topship"
                className="w-[4rem] h-[4rem] object-cover"
              />
              <p className="font-rubik text-dark text-[1.6rem]">
                United Parcel Service
              </p>
            </div>
            <div className="bg-white rounded-[1rem] p-[0.8rem] gap-[0.8rem] flex items-center">
              <img
                src="/fedex.png"
                alt="topship"
                className="w-[4rem] h-[4rem] object-cover"
              />
              <p className="font-rubik text-dark text-[1.6rem]">
                Federal Express
              </p>
            </div>
            <div className="bg-white rounded-[1rem] p-[0.8rem] gap-[0.8rem] flex items-center">
              <img
                src="/kwik.png"
                alt="topship"
                className="w-[4rem] h-[4rem] object-cover"
              />
              <p className="font-rubik text-dark text-[1.6rem]">
                Kwik Delivery
              </p>
            </div>
            <div className="bg-white rounded-[1rem] p-[0.8rem] gap-[0.8rem] flex items-center">
              <img
                src="/dhl.png"
                alt="topship"
                className="w-[4rem] h-[4rem] object-cover"
              />
              <p className="font-rubik text-dark text-[1.6rem]">
                Dalsey Hillblom Lynn
              </p>
            </div>
            <div className="bg-white rounded-[1rem] p-[0.8rem] gap-[0.8rem] flex items-center">
              <img
                src="/g.png"
                alt="topship"
                className="w-[4rem] h-[4rem] object-cover"
              />
              <p className="font-rubik text-dark text-[1.6rem]">
                Gokada Delivery
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Launch;

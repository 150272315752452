"use client";
import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import html2canvas from "html2canvas";
import Creatable from "react-select/creatable";
import format from "date-fns/format";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { toast } from "react-toastify";
import {
  getCities,
  getStates,
  uploadPriceList,
} from "../../services/miscService";
import { useNavigate } from "react-router-dom";

const MAX_IMAGE_SIZE_BYTES = 2 * 1024 * 1024;

const currencies = [
  { value: "₦", label: "NGN" },
  { value: "$", label: "USD" },
  { value: "€", label: "Euro" },
  { value: "£", label: "Pound sterling" },
];

const PriceListGenerator = () => {
//   const ref = useRef();
  const navigate = useNavigate();

  const [isUploading, setUploading] = useState(false);
  const [color, setColor] = useState("#075C3E");
  const [header, setHeader] = useState("Your Header Text");
  const [phone, setPhone] = useState("08030000000");
  const [social, setSocial] = useState("business_handle");
  const [website, setWebsite] = useState("yourbusiness.com");
  const [address, setAddress] = useState("Your business address");
  const [deliveryLocations, setDeliveryLocations] = useState([
    {
      pickup_state: "",
      pickup_city: "",
      dropoff_state: "",
      dropoff_city: "",
      currency: "₦",
      price: "",
    },
  ]);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchStates = async () => {
      const arr = [];
      await getStates().then((res) => {
        let results = res.data;
        results.map((result) => {
          return arr.push({
            value: `${result.name}-${result.id}`,
            label: result.name,
          });
        });
        setStates(arr);
      });
    };
    const fetchCities = async () => {
      const arr = [{ id: 0, state_id: 0, name: "Any City" }];
      await getCities().then((results) => {
        //   let results = res.data;
        results.map((result) => {
          return arr.push({
            id: result.id,
            state_id: result.state_id,
            name: result.name,
          });
        });
        setCities(arr);
      });
    };
    fetchStates();
    fetchCities();
  }, []);

  const [image, setImage] = useState(null);
  const inputRef = useRef();

  const targetRef = useRef(null);

  const getFileName = (fileType) =>
    `price-list-${format(new Date(), "HH-mm-ss")}.${fileType}`;

  const handleDownloadImage = async () => {
    setUploading(true);
    uploadPriceList({
      color,
      header,
      phone,
      website,
      address,
      social_handle: social,
      logo: image,
      prices: deliveryLocations,
    })
      .then(async (results) => {
        console.log(results);
        const element = targetRef.current;
        const canvas = await html2canvas(element, {
          windowWidth: "1400px",
          onclone: function (document) {
            document.querySelector(".cle").style.lineHeight = 2.5;
            document.querySelector(".cle-2").style.lineHeight = 2.5;
          },
        });

        const data = canvas.toDataURL("image/jpg");
        const link = document.createElement("a");

        if (typeof link.download === "string") {
          link.href = data;
          link.download = getFileName("jpg");

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          window.open(data);
        }
        setUploading(false);
      })
      .catch(() => {
        setUploading(false);
      });
  };

  const handlePickupStateChange = function (index, title, value) {
    const data = deliveryLocations.slice(); // Make a copy of the emails first.
    data[index][title] = value; // Update it with the modified email.
    setDeliveryLocations(data); // Update the state.
  };

  return (
    <>
      <Helmet>
        <title>Delivery Page — Price List Generator</title>
        <meta name="title" content="Price List Generator" />
        <meta
          name="description"
          content="Easiest Way to Take Your Logistics Business Online"
        />
      </Helmet>

      <Navbar />
      <div className="relative mb-[12rem] flex flex-col items-center overflow-hidden">
        <div className="flex flex-col pt-[6.8rem] px-6 md:px-0">
          {/* <div className="flex flex-col pt-[9.8rem] px-6 md:px-0"> */}
          <div className="max-w-[1150px] flex items-center">
            <div
              className="border border-[#707070] bg-[#7070700D] py-[8px] md:py-[12px] px-[16px] md:px-[32px] rounded-lg cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <p className="text-[12px] md:text-[16px] text-[#707070]">
                {"<"} <span className="ml-4">Back</span>
              </p>
            </div>
            <h2 className="font-recoleta text-[2.2rem] md:text-[4rem] font-[600] text-accent text-center mx-auto">
              Price List Generator
            </h2>
          </div>

          <div className="relative h-screen overflow-y-auto w-[90vw] mx-auto flex flex-col md:flex-row items-start gap-[3.2rem] mt-[8.5rem] p-[20px] md:p-[40px] rounded-2xl bg-[#F8F8F8]">
            {/* First column */}
            <div className="bg-[#FFFFFF] py-[24px] w-[100%] md:w-[40%] border border-[#27306633] rounded-xl">
              <div className="px-[16px] md:px-[32px] mb-[24px]">
                <h2 className="text-[2rem] md:text-[2.3rem] font-semibold text-accent font-recoleta mb-[4px]">
                  Price List Generator
                </h2>
                <p className="text-[#0D1326CC] text-[16px]">
                  Quickly generate a pricing list for your shipping business
                  today.
                </p>
              </div>

              <hr />

              <div className="px-[16px] md:px-[32px] my-[24px]">
                <p className="text-[#0D1326] text-[14px] mb-[12px]">
                  Background
                </p>
                <div className="flex flex-col md:flex-row gap-[14px]">
                  {/* <ColorPicker /> */}
                  <label className="relative">
                    <input
                      type="color"
                      value="#075C3E"
                      style={{ visibility: "hidden", position: "absolute" }}
                      //   ref={ref}
                    //   onChange={(v) => {
                    //     if (v?.currentTarget?.value) {
                    //       setColor(v.currentTarget.value);
                    //     }
                    //   }}
                      onInput={(v) => {
                        if (v?.currentTarget?.value) {
                          setColor(v.currentTarget.value);
                        }
                      }}
                    />
                    <div
                      className={`flex gap-[6px] items-center border border-[#273066] rounded-md py-[4px] pl-[4px] pr-[8px] cursor-pointer`}
                      //   onClick={() => setColor(c)}
                    >
                      <div
                        className={`h-[40px] w-[40px] rounded`}
                        style={{ backgroundColor: color }}
                      ></div>

                      <p className="text-[16px] text-[400] text-[#282828]">
                        {color}
                      </p>
                    </div>
                  </label>
                  <div className="flex gap-[14px]">
                    {["#39C3EF", "#C6CA1B", "#6415C9", "#0060D1"].map((c) => {
                      const w = color === c ? "w-[40px]" : "w-[48px]";
                      const h = color === c ? "h-[40px]" : "h-[48px]";
                      return (
                        <div
                          key={c}
                          className={`flex gap-[6px] items-center ${
                            color === c ? "border border-[#273066]" : ""
                          } rounded py-[1px] px-[4px] cursor-pointer`}
                          onClick={() => setColor(c)}
                        >
                          <div
                            className={`${h} ${w} rounded`}
                            style={{ backgroundColor: c }}
                          ></div>
                          {/* {color === c && (
                            <p className="text-[16px] text-[#282828]">{c}</p>
                          )} */}
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* Header text editor */}
                <div className="col-span-full mt-[24px]">
                  <label
                    htmlFor="header-text"
                    className="block text-[14px] font-[400] leading-6 text-[#282828] mb-[8px]"
                  >
                    Header
                  </label>
                  <div className="mt-1">
                    <input
                      onChange={(v) => {
                        const value = v?.currentTarget?.value;
                        if (value?.length < 1) {
                          setHeader("");
                        } else {
                          setHeader(value);
                        }
                      }}
                      value={header}
                      type="text"
                      name="header-text"
                      placeholder="Enter Header Text"
                      id="header-text"
                      autoComplete="header-text"
                      className="block w-full rounded border-[0.8px] border-[#757577] px-[16px] py-[15px] shadow-sm text-[#757577] outline-none text-[14px]"
                    />
                  </div>
                </div>

                {/* Logo editor */}
                <div className="col-span-full mt-[24px]">
                  <label
                    htmlFor="logo"
                    className="block text-[14px] font-[400] leading-6 text-[#282828] mb-[8px]"
                  >
                    Logo
                  </label>
                  <div
                    onClick={() => inputRef?.current?.click()}
                    className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 relative cursor-pointer"
                  >
                    <input
                      ref={inputRef}
                      onChange={(event) => {
                        if (event?.currentTarget?.files) {
                          const file = event?.currentTarget?.files[0];
                          if (file.size > MAX_IMAGE_SIZE_BYTES) {
                            toast.error(
                              "Image size exceeds the maximum allowed size (2MB). Please select a smaller image."
                            );
                            return;
                          }
                          setImage(URL.createObjectURL(event.target.files[0]));
                        }
                      }}
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                    />
                    <div className="text-center">
                      <div className="mt-4 flex leading-6 text-[#757577] items-center gap-[6px] text-[12px]">
                        <img src="/icons/upload.svg" alt="upload icon" />
                        <span>Upload Logo</span>
                        {/* <p className="pl-1">or drag and drop</p> */}
                      </div>
                      <p className="text-lg leading-5 text-gray-600 mt-[6px]">
                        PNG, JPG up to 4MB
                      </p>
                    </div>
                  </div>
                </div>

                {/* Phone number editor */}
                <div className="col-span-full mt-[24px]">
                  <label
                    htmlFor="header-text"
                    className="block text-[14px] font-[400] leading-6 text-[#282828] mb-[8px]"
                  >
                    Phone Number
                  </label>
                  <div className="mt-2">
                    <input
                      onChange={(v) => {
                        const value = v?.currentTarget?.value;
                        if (value?.length < 1) {
                          setPhone(null);
                        } else {
                          setPhone(value);
                        }
                      }}
                      value={phone}
                      type="text"
                      name="header-text"
                      placeholder="Enter Phone Number"
                      id="header-text"
                      autoComplete="header-text"
                      className="block w-full rounded border-[0.8px] border-[#757577] px-[16px] py-[15px] shadow-sm text-[#757577] outline-none text-[14px]"
                    />
                  </div>
                </div>

                {/* Social Handle editor */}
                <div className="col-span-full mt-[24px]">
                  <label
                    htmlFor="header-text"
                    className="block text-[14px] font-[400] leading-6 text-[#282828] mb-[8px]"
                  >
                    Social Handle
                  </label>
                  <div className="mt-2">
                    <input
                      onChange={(v) => {
                        const value = v?.currentTarget?.value;
                        if (value?.length < 1) {
                          setSocial(null);
                        } else {
                          setSocial(value);
                        }
                      }}
                      value={social}
                      type="text"
                      name="header-text"
                      placeholder="Enter Social Handle"
                      id="header-text"
                      autoComplete="header-text"
                      className="block w-full rounded border-[0.8px] border-[#757577] px-[16px] py-[15px] shadow-sm text-[#757577] outline-none text-[14px]"
                    />
                  </div>
                </div>

                {/* Website address editor */}
                <div className="col-span-full mt-[24px]">
                  <label
                    htmlFor="header-text"
                    className="block text-[14px] font-[400] leading-6 text-[#282828] mb-[8px]"
                  >
                    Website
                  </label>
                  <div className="mt-2">
                    <input
                      onChange={(v) => {
                        const value = v?.currentTarget?.value;
                        if (value?.length < 1) {
                          setWebsite("");
                        } else {
                          setWebsite(value);
                        }
                      }}
                      value={website}
                      type="text"
                      name="header-text"
                      placeholder="Enter Website Address"
                      id="header-text"
                      autoComplete="header-text"
                      className="block w-full rounded border-[0.8px] border-[#757577] px-[16px] py-[15px] shadow-sm text-[#757577] outline-none text-[14px]"
                    />
                  </div>
                </div>

                {/* Address editor */}
                <div className="col-span-full mt-[24px]">
                  <label
                    htmlFor="header-text"
                    className="block text-[14px] font-[400] leading-6 text-[#282828] mb-[8px]"
                  >
                    Address
                  </label>
                  <div className="mt-2">
                    <input
                      onChange={(v) => {
                        const value = v?.currentTarget?.value;
                        if (value?.length < 1) {
                          setAddress("");
                        } else {
                          setAddress(value);
                        }
                      }}
                      value={address}
                      type="text"
                      name="header-text"
                      placeholder="Enter Contact Address"
                      id="header-text"
                      autoComplete="header-text"
                      className="block w-full rounded border-[0.8px] border-[#757577] px-[16px] py-[15px] shadow-sm text-[#757577] outline-none text-[14px]"
                    />
                  </div>
                </div>
              </div>

              <hr />

              {/* Delivery locations editor  */}

              {/* {deliveryLocations[0].pickup_state} */}
              <div className="px-[16px] md:px-[32px] my-[24px]">
                {deliveryLocations.map((deliveryLocation, index) => {
                  console.log(index);
                  const pickupCitiesByState = cities
                    .filter(
                      (city) =>
                        city?.state_id ===
                          parseInt(
                            deliveryLocation.pickup_state.split("-")[1]
                          ) || city?.state_id === 0
                    )
                    .map((city) => ({
                      value: city?.name,
                      label: city?.name,
                    }));

                  const dropoffCitiesByState = cities
                    .filter(
                      (city) =>
                        city?.state_id ===
                          parseInt(
                            deliveryLocation.dropoff_state.split("-")[1]
                          ) || city?.state_id === 0
                    )
                    .map((city) => ({
                      value: city?.name,
                      label: city?.name,
                    }));
                  return (
                    <div key={index}>
                      <div
                        // key={index}
                        className="mb-[24px] grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
                      >
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-[14px] font-[400] leading-6 text-[#282828] mb-[12px]"
                          >
                            Pickup State
                          </label>
                          <Creatable
                            defaultValue={deliveryLocation.pickup_state}
                            value={states.filter(function (option) {
                              return (
                                option.value === deliveryLocation.pickup_state
                              );
                            })}
                            options={states}
                            styles={{
                              input: (base) => ({
                                ...base,
                                padding: "8px 16px",
                              }),

                              control: (base) => ({
                                ...base,
                                border: 0,
                                boxShadow: "none",
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isSelected
                                  ? "#757577"
                                  : "white",
                              }),
                              singleValue: (base) => ({
                                ...base,
                                color: "#757577",
                              }),
                            }}
                            className="rounded-md border-[0.8px] border-[#757577] text-[14px] text-[#757577] font-gordita"
                            placeholder="Select State"
                            onChange={(selected) => {
                              const exists = states.some(
                                (state) => state.value === selected?.value
                              );
                              // If the item doesn't exist, concatenate it to the array
                              if (!exists) {
                                setStates((prev) =>
                                  prev.concat({
                                    value: selected?.value,
                                    label: selected?.value,
                                  })
                                );
                              }
                              handlePickupStateChange(
                                index,
                                "pickup_state",
                                selected?.value
                              );
                            }}
                          />
                          {/* <select
                              id="country"
                              style={selectStyle}
                              value={deliveryLocations[index].pickup_state}
                              onChange={(v) =>
                                handlePickupStateChange(
                                  index,
                                  "pickup_state",
                                  v
                                )
                              }
                              name="country"
                              // placeholder="jjjjj"
                              autoComplete="country-name"
                              className="pr-[28px] block w-full rounded-md border border-gray-300 p-[16px] text-[#757577] shadow-sm focus:ring-0 active:ring-0 active:border-0 text-[14px] sm:leading-6"
                            >
                              <option value="">Select state</option>
                              {State.getStatesOfCountry("NG").map((state) => (
                                <option
                                  value={`${state.name}-${state.isoCode}`}
                                >
                                  {state.name}
                                </option>
                              ))}
                            </select> */}
                          {/* </div> */}
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-[14px] font-[400] leading-6 text-[#282828] mb-[12px]"
                          >
                            Pickup City
                          </label>
                          <Creatable
                            defaultValue={deliveryLocation.pickup_city}
                            value={pickupCitiesByState.filter(function (
                              option
                            ) {
                              return (
                                option.value === deliveryLocation.pickup_city
                              );
                            })}
                            styles={{
                              input: (base) => ({
                                ...base,
                                padding: "8px 16px",
                              }),

                              control: (base) => ({
                                ...base,
                                border: 0,
                                boxShadow: "none",
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isSelected
                                  ? "#757577"
                                  : "white",
                              }),
                              singleValue: (base) => ({
                                ...base,
                                color: "#757577",
                              }),
                            }}
                            className="rounded-md border-[0.8px] border-[#757577] text-[14px] text-[#757577] font-gordita"
                            placeholder="Select City"
                            onChange={(selected) => {
                              const exists = cities.some(
                                (city) => city.name === selected?.value
                              );
                              // If the item doesn't exist, concatenate it to the array
                              if (!exists) {
                                setCities((prev) =>
                                  prev.concat({
                                    id: new Date().valueOf(),
                                    state_id: 0,
                                    name: selected?.value,
                                  })
                                );
                              }
                              const isAnyCity = selected?.value === "Any City";
                              handlePickupStateChange(
                                index,
                                "pickup_city",
                                isAnyCity ? null : selected?.value
                              );
                            }}
                            options={pickupCitiesByState}
                          />
                          {/* <div className="mt-2">
                            <select
                              onChange={(v) =>
                                handlePickupStateChange(index, "pickup_city", v)
                              }
                              id="pickup-city"
                              name="pickup-city"
                              placeholder="Pickup city"
                              autoComplete="country-name"
                              className="block w-full rounded-md border-r-4 border border-gray-300 p-[13px] text-[#757577] shadow-sm focus:ring-0 active:ring-0 active:border-0 text-[14px] sm:leading-6"
                            >
                              <option value="">Select city</option>
                              {City.getCitiesOfState(
                                "NG",
                                deliveryLocation.pickup_state.split("-")[1]
                              ).map((city) => (
                                <option value={city.name}>{city.name}</option>
                              ))}
                            </select>
                          </div> */}
                        </div>
                      </div>

                      {/* Dropoff */}

                      <div className="mb-[24px] grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-[14px] font-[400] leading-6 text-[#282828] mb-[12px]"
                          >
                            Dropoff State
                          </label>
                          <Creatable
                            defaultValue={deliveryLocation.dropoff_state}
                            value={states.filter(function (option) {
                              return (
                                option.value === deliveryLocation.dropoff_state
                              );
                            })}
                            styles={{
                              input: (base) => ({
                                ...base,
                                padding: "8px 16px",
                              }),

                              control: (base) => ({
                                ...base,
                                border: 0,
                                boxShadow: "none",
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isSelected
                                  ? "#757577"
                                  : "white",
                              }),
                              singleValue: (base) => ({
                                ...base,
                                color: "#757577",
                              }),
                            }}
                            className="rounded-md border-[0.8px] border-[#757577] text-[14px] text-[#757577] font-gordita"
                            placeholder="Select State"
                            onChange={(selected) => {
                              const exists = states.some(
                                (state) => state.value === selected?.value
                              );
                              // If the item doesn't exist, concatenate it to the array
                              if (!exists) {
                                setStates((prev) =>
                                  prev.concat({
                                    value: selected?.value,
                                    label: selected?.value,
                                  })
                                );
                              }
                              handlePickupStateChange(
                                index,
                                "dropoff_state",
                                selected?.value
                              );
                            }}
                            options={states}
                          />
                          {/* <div className="mt-2">
                            <select
                              onChange={(v) =>
                                handlePickupStateChange(
                                  index,
                                  "dropoff_state",
                                  v
                                )
                              }
                              id="country"
                              name="country"
                              autoComplete="country-name"
                              className="block w-full rounded-md border-r-4 border border-gray-300  p-[13px] text-[#757577] shadow-sm focus:ring-0 active:ring-0 active:border-0 text-[14px] sm:leading-6"
                            >
                              <option value="">Select state</option>
                              {State.getStatesOfCountry("NG").map((state) => (
                                <option
                                  value={`${state.name}-${state.isoCode}`}
                                >
                                  {state.name}
                                </option>
                              ))}
                            </select>
                          </div> */}
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-[14px] font-[400] leading-6 text-[#282828] mb-[12px]"
                          >
                            Drop off City
                          </label>
                          <Creatable
                            defaultValue={deliveryLocation.dropoff_city}
                            value={dropoffCitiesByState.filter(function (
                              option
                            ) {
                              return (
                                option.value === deliveryLocation.dropoff_city
                              );
                            })}
                            styles={{
                              input: (base) => ({
                                ...base,
                                padding: "8px 16px",
                              }),

                              control: (base) => ({
                                ...base,
                                border: 0,
                                boxShadow: "none",
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isSelected
                                  ? "#757577"
                                  : "white",
                              }),
                              singleValue: (base) => ({
                                ...base,
                                color: "#757577",
                              }),
                            }}
                            className="rounded-md border-[0.8px] border-[#757577] text-[14px] text-[#757577] font-gordita"
                            placeholder="Select City"
                            onChange={(selected) => {
                              const exists = cities.some(
                                (city) => city.name === selected?.value
                              );
                              // If the item doesn't exist, concatenate it to the array
                              if (!exists) {
                                setCities((prev) =>
                                  prev.concat({
                                    id: new Date().valueOf(),
                                    state_id: 0,
                                    name: selected?.value,
                                  })
                                );
                              }
                              const isAnyCity = selected?.value === "Any City";
                              handlePickupStateChange(
                                index,
                                "dropoff_city",
                                isAnyCity ? null : selected?.value
                              );
                            }}
                            options={dropoffCitiesByState}
                          />
                        </div>
                      </div>

                      {/* Currency & amount */}
                      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-[14px] font-[400] leading-6 text-[#282828] mb-[12px]"
                          >
                            Currency
                          </label>
                          <Creatable
                            defaultValue={deliveryLocation.currency}
                            value={currencies.filter(function (option) {
                              return option.value === deliveryLocation.currency;
                            })}
                            styles={{
                              input: (base) => ({
                                ...base,
                                padding: "8px 16px",
                              }),

                              control: (base) => ({
                                ...base,
                                border: 0,
                                boxShadow: "none",
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isSelected
                                  ? "#757577"
                                  : "white",
                              }),
                              singleValue: (base) => ({
                                ...base,
                                color: "#757577",
                              }),
                            }}
                            className="rounded-md border-[0.8px] border-[#757577] text-[14px] text-[#757577] font-gordita"
                            placeholder="Select Currency"
                            onChange={(selected) => {
                              handlePickupStateChange(
                                index,
                                "currency",
                                selected?.value
                              );
                            }}
                            options={currencies}
                          />
                          {/* <div className="mt-2">
                            <select
                              onChange={(v) =>
                                handlePickupStateChange(index, "currency", v)
                              }
                              id="country"
                              name="country"
                              autoComplete="country-name"
                              className="block w-full rounded-md border-r-4 border border-gray-300 p-[13px] text-[#757577] shadow-sm focus:ring-0 active:ring-0 active:border-0 text-[14px] sm:leading-6"
                            >
                              <option value="">Select Currency</option>
                              <option value="₦">NGN</option>
                              <option value="$">USD</option>
                              <option value="KES">KES</option>
                            </select>
                          </div> */}
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="block text-[14px] font-[400] leading-6 text-[#282828] mb-[12px]"
                          >
                            Custom Price
                          </label>
                          <input
                            value={deliveryLocation.price}
                            onChange={(v) =>
                              handlePickupStateChange(
                                index,
                                "price",
                                v?.target?.value
                              )
                            }
                            type="number"
                            name="price"
                            placeholder="e.g 1,500"
                            id="last-name"
                            autoComplete="family-name"
                            className="block w-full rounded-md border-[0.8px] border-[#757577] px-[16px] py-[12px] text-[#757577] outline-none text-[14px]"
                          />
                        </div>
                      </div>

                      {deliveryLocations.length > 1 && (
                        <button
                          onClick={() => {
                            const data = [...deliveryLocations];
                            data.splice(index, 1);
                            setDeliveryLocations(data);

                            // const updatedDeliveryLocations =
                            //   deliveryLocations.filter((_, i) => i !== index);
                            // setDeliveryLocations(updatedDeliveryLocations);
                          }}
                          className="order-last md:order-first rounded text-[#E62965] py-[12px] text-[14px] font-semibold mb-2"
                        >
                          <div className="flex gap-[8px] justify-center">
                            <img
                              src="/icons/delete_forever.svg"
                              alt="add-icon"
                            />
                            <span>Delete</span>
                          </div>
                        </button>
                      )}

                      {deliveryLocations.length - 1 > index && (
                        <hr className="mb-10" />
                      )}
                    </div>
                  );
                })}

                <div className="mt-8 flex flex-col md:flex-row justify-end gap-[16px]">
                  {/* {deliveryLocations.length > 1 && (
                    <button
                      onClick={() => {
                        const data = deliveryLocations.slice();
                        data.pop();
                        setDeliveryLocations(data);
                      }}
                      className="order-last md:order-first rounded text-[#E62965] border-1 border-[#273066] px-[24px] py-[12px] text-[14px] font-semibold shadow-sm"
                    >
                      <div className="flex gap-[8px] justify-center">
                        <img src="/icons/delete_forever.svg" alt="add-icon" />
                        <span>Delete</span>
                      </div>
                    </button>
                  )} */}
                  <button
                    onClick={() => {
                      const data = deliveryLocations.slice();
                      data.push({
                        pickup_state: "",
                        pickup_city: "",
                        dropoff_state: "",
                        dropoff_city: "",
                        currency: "₦",
                        price: "",
                      });
                      setDeliveryLocations(data);
                    }}
                    className="rounded text-[#273066] border-[1px] border-[#273066] px-[24px] min-w-[200px] py-[12px] text-[14px] font-semibold text-white shadow-sm"
                  >
                    <div className="flex gap-[8px] justify-center">
                      <img src="/icons/add.svg" alt="add-icon" />
                      <span>Add More</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>

            {/* Second column */}
            <div className="flex-1 w-[100%] sticky top-0">
              <div className="mb-[24px] flex justify-start md:justify-end gap-[24px]">
                {/* {preview && (
                  <button
                    onClick={() => {
                      toPDF({
                        filename: `price-list-${new Date().valueOf()}.pdf`,
                      });
                    }}
                    className="rounded bg-[#273066] px-[24px] min-w-[200px] py-[12px] text-[14px] font-semibold text-white shadow-sm hover:bg-[#273058]"
                  >
                    Download Price List
                  </button>
                )} */}

                <button
                  onClick={() => handleDownloadImage()}
                  //   onClick={() => setPreview(!preview)}
                  className="button rounded bg-[#273066] px-[24px] min-w-[200px] md:min-w-[312px] min-h-[48px] text-[14px] font-semibold text-white shadow-sm hover:bg-[#273058]"
                >
                  {isUploading && (
                    <>
                      <svg
                        aria-hidden="true"
                        class="inline mr-4 w-9 h-9 text-gray-100 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </>
                  )}
                  Download Price List
                </button>
              </div>

              <div
                ref={targetRef}
                className={`text-white px-[16px] md:px-[40px] py-[24px] md:py-[48px] rounded-xl`}
                style={{ backgroundColor: color }}
                // className={`bg-[${color}] text-white px-[40px] py-[48px] rounded-xl w-[100vw]`}
              >
                {image && (
                  <div className="flex justify-center mb-[24px]">
                    <img alt="preview" src={image} width="200px" />
                  </div>
                )}
                <h2 className="font-recoleta font-bold text-[40px] text-center mb-[16px]">
                  {header}
                </h2>
                {/* <div class="rounded-lg text-[14px] text-[#282828] bg-[#FFFFFF]"> */}
                <div className="relative overflow-x-auto bg-[#F1F1F1] rounded-2xl">
                  <table className="rounded-xl w-[100%] text-[14px] text-[#282828]">
                    <thead className="uppercase font-medium rounded-2xl text-left">
                      {/* <thead className="uppercase bg-[#F1F1F1] font-medium rounde-lg"> */}
                      <tr>
                        <th className="border-b-[1px] border-r border-slate-600 p-[12px]">
                          Pickoff Location
                        </th>
                        <th className="border-b-[1px] border-r border-slate-600  p-[12px]">
                          Dropoff Location
                        </th>
                        <th className="border-b-[1px] border-slate-600 p-[12px]">
                          Price
                        </th>
                      </tr>
                    </thead>
                    <tbody className="uppercase bg-[#FFFFFF] rounded-2xl">
                      {deliveryLocations.map((deliveryLocation) => (
                        <tr className="rounded">
                          <td className="border-b-[1px] border-r border-slate-700 py-[14px] px-[24px]">
                            {deliveryLocation.pickup_state?.split("-")[0]}
                            {deliveryLocation.pickup_state &&
                              deliveryLocation.pickup_city &&
                              " - "}
                            {deliveryLocation.pickup_city}
                          </td>
                          <td className="border-b-[1px] border-r border-slate-700 py-[14px] px-[24px]">
                            {deliveryLocation.dropoff_state?.split("-")[0]}
                            {deliveryLocation.dropoff_state &&
                              deliveryLocation.dropoff_city &&
                              " - "}
                            {deliveryLocation.dropoff_city}
                          </td>
                          <td className="border-b-[1px] border-slate-700 py-[14px] px-[24px]">
                            {deliveryLocation.currency}{" "}
                            {`${Number(
                              deliveryLocation.price
                            ).toLocaleString()}`}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div
                  //   id="cle"
                  className="flex flex-col md:flex-row justify-center gap-[24px] mt-[40px] text-[16px] cle"
                  //   style={{ lineHeight: "2.5" }}
                >
                  {phone && (
                    <div className="flex items-end content-center gap-[8px]">
                      <img src="/icons/add_to_home_screen.svg" alt="icon" />
                      <p>{phone}</p>
                    </div>
                  )}

                  {website && (
                    <div className="flex items-end content-center gap-[8px]">
                      <img src="/icons/captive_portal.svg" alt="icon" />
                      <p>{website}</p>
                    </div>
                  )}

                  {social && (
                    <div className="flex items-end content-center gap-[8px]">
                      <img src="/icons/share_reviews.svg" alt="icon" />
                      <p>{social}</p>
                    </div>
                  )}
                </div>
                {address && (
                  <div className="flex flex-row md:justify-center gap-[24px] mt-[16px] text-[16px] cle-2">
                    <div className="flex items-end content-center gap-[8px]">
                      <img
                        src="/icons/local_convenience_store.svg"
                        alt="icon"
                      />
                      <p>{address}</p>
                      {/* <p>4517 Washington Ave. Manchester, Kentucky 39495</p> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="max-w-[1138px] mx-auto grid grid-cols-2 md:grid-cols-5 gap-[3.2rem] mt-[8.5rem]"> */}
            {/* {[1, 2, 3, 4, 5, 6, 7, 8].map((value) => (
              <div
                className="border bg-white border-opacity-50 py-[16px] px-[24px] rounded-[1.2rem] cursor-pointer"
                onClick={() => navigate("/tools/price-list-generator")}
              >
                <h2 className="text-[2rem] md:text-[2.3rem] font-semibold text-accent font-recoleta mb-[4px]">
                  Free Delivery Tool
                </h2>
                <p className="text-[16px]">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo.
                </p>
              </div>
            ))} */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PriceListGenerator;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import subService from "../../services/subService";

const initialState = {
  plans: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  errorData: {}
};

export const subscribe = createAsyncThunk(
  "subscription",
  async (_, thunkAPI) => {
    try {
      return await subService.subscribe();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue({
        message,
        errorData: error.response?.data?.errors || {},
      });
    }
  }
);

const subscriptionSlice = createSlice({
  name: "subscribe",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(subscribe.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(subscribe.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = true;
        state.plans = action.payload;
      })
      .addCase(subscribe.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false
        state.isError = true;
        state.message = action.payload.message;
        state.errorData = action.payload.errorData;
        state.user = null;
      });
  },
});

export const { reset } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;

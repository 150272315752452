import React from "react";

const Compare = () => {
  return (
    <div className="flex flex-col mt-[8rem] md:mt-[14.4rem] px-8 md:px-0 w-full">
      <div className="max-w-[1080px] mx-auto grid grid-cols-1 md:grid-cols-2 items-center gap-[7rem] md:gap-[7.8rem]">
        <div>
          <h2 className="text-dark hidden md:block font-recoleta text-[5.6rem] font-medium leading-[8.6rem]">
            Compare<br></br> Delivery Rate
          </h2>
          <h2 className="text-dark block md:hidden font-recoleta text-[5.6rem] font-semibold leading-[7.6rem]">
            Compare Delivery Rate
          </h2>
          <p className="text-[20px] leading-[3.2rem] font-rubik font-light text-dark mt-[1.6rem] mb-[3.6rem]">
            Compare prices between different credible delivery carriers and save
            cost on local and international deliveries. You can make the best
            choice for your business based on delivery cost, time to delivery,
            carrier capabilities and more. More options mean that you can truly
            customize your workflows.
          </p>
          <div className="">
            <button className="bg-accent text-white py-[1.6rem] px-[3rem] md:px-[4.8rem] rounded-[0.8rem] font-rubik text-[1.6rem] font-nrmal">
              Get Started
            </button>
          </div>
        </div>
        <img src="/compares.png" alt="api hero" className="w-full h-full" />
      </div>
    </div>
  );
};

export default Compare;

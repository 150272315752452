import React, { useState } from "react";
import Logo from "../assets/logo.png";
import footerLogo from "../assets/footerlogo.svg";
import { FaBars } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const links = [
    // {
    //   name: "home",
    //   link: "home",
    // },
    {
      name: "solutions",
      link: "features",
    },
    {
      name: "Why choose us",
      link: "choose-us",
    },
    {
      name: "pricing",
      link: "prices",
    }
  ];

  return (
    <div className="w-screen grid h-[8.4rem] py-[1.2rem] text-dark z-2 overflow-x-hidden">
      <div className="px-4 md:px-12 w-full h-full flex justify-between items-center">
        {/* Logo */}
        <NavLink to="/" className="flex items-center cursor-pointer">
          <img src={Logo} alt="site logo" className="w-[18rem]" />
        </NavLink>
        {/* list */}
        <ul className="hidden md:flex">
          {links.map(({ name, link }) => (
            <li
              key={name}
              className="capitalize px-[2.5rem] cursor-pointer font-normal text-[1.6rem] font-rubik text-dark hover:text-blue duration:200"
            >
              <Link to={link} smooth duration={1500}>
                {name}
              </Link>
            </li>
          ))}
          <li className="capitalize px-[2.5rem] cursor-pointer font-normal text-[1.6rem] font-rubik text-dark hover:text-blue duration:200">
            <NavLink to="/tools">Free Tools</NavLink>
          </li>
          <li className="capitalize px-[2.5rem] cursor-pointer font-normal text-[1.6rem] font-rubik text-dark hover:text-blue duration:200">
            <NavLink to="/developers">Developers</NavLink>
          </li>
          <li className="capitalize px-[2.5rem] cursor-pointer font-normal text-[1.6rem] font-rubik text-dark hover:text-blue duration:200">
            <NavLink to="/academy">Resources</NavLink>
          </li>
          <li className="capitalize px-[2.5rem] cursor-pointer font-normal text-[1.6rem] font-rubik text-dark hover:text-blue duration:200">
            <a href="https://blog.deliverypage.africa" smooth duration={1500}>
              Blog
            </a>
          </li>
        </ul>
        {/* Button */}
        <NavLink className="hidden md:flex mr-2" to="/request-demo">
          <button
            className="rounded-lg
            text-white
            border
            font-normal
            text-[1.6rem] py-[1.4rem] px-[3.2rem] border-blue bg-darkBlue font-rubik"
          >
            Request a Demo
          </button>
        </NavLink>
        {/* Burger Icon */}
        <div className="md:hidden">
          <div className="cursor-pointer mr-4" onClick={() => setNav(true)}>
            <FaBars size={25} style={{ color: "#0D1326" }} />
          </div>
          {/* Mobile Menu */}
          <div
            className={
              nav
                ? "w-full h-full z-50 fixed left-0 top-0 bg-gray-900/70 text-white md:hidden backdrop-blur"
                : null
            }
          >
            <div
              className={
                nav
                  ? "fixed w-4/5 bg-darkBlue left-0 top-0 text-white h-full p-10 pt-10 ease-in duration-500"
                  : "fixed p-5 top-0 left-[-100%] duration-500 h-full ease-in"
              }
            >
              <div className="w-full flex justify-between items-center mb-[5rem]">
                <div
                  className="cursor-pointer p-0"
                  onClick={() => setNav(false)}
                >
                  <img src={footerLogo} alt="site logo" />
                </div>
                <div
                  className="p-4 cursor-pointer"
                  onClick={() => setNav(false)}
                >
                  <FaTimes size={30} />
                </div>
              </div>
              <div className="flex flex-col text-white justify-between h-fit">
                <ul className="capitalize mb-8">
                  {links.map(({ name, link }) => (
                    <li
                      key={name}
                      className="text-[1.6rem] tracking-wider py-8 cursor-pointer"
                    >
                      <Link
                        to={link}
                        smooth
                        duration={1500}
                        onClick={() => setNav(false)}
                      >
                        {name}
                      </Link>
                    </li>
                  ))}
                </ul>
                <NavLink to="/request-demo">
                  <button
                    className="bg-white text-dark py-[1.4rem] px-[3.2rem] rounded-lg font-rubik
                  font-bold
                  text-[1.6rem]"
                  >
                    Request a demo
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

import { FaTimes } from "react-icons/fa";
import Loader from "../Loader";

const VideoModal = ({ video, onClose, isVideoLoading, setIsVideoLoading }) => {
  return (
    <div className="w-full inset-0 bg-black bg-opacity-95 py-[30px] backdrop-blur-sm fixed">
      <div className="bg-white relative w-[60%] h-full mx-auto pb-[100px] pt-[40px] px-16 rounded-[20px]">
        <div className="aspect-video w-full mb-[500px]">
          {isVideoLoading ? (
            <Loader />
          ) : (
            <iframe
              className="w-full h-full object-cover"
              title={video.title}
              src={video.resource_url}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
          <FaTimes
            className="absolute top-5 right-8 cursor-pointer"
            size={24}
            onClick={onClose}
          />
          <h2 className="text-accent text-[24px] mt-8 mb-4 font-bold">
            {video.title}
          </h2>
          <p className="text-accent text-[18px] font-normal">
            {video.short_description}
          </p>
        </div>
      </div>
    </div>
  );
};
export default VideoModal;

import React from "react";

const Cooky = () => {
  return (
    <div>
      <div className="max-w-screen-lg mx-auto text-center p-8 pt-[10rem]">
        <h2 className="font-recoleta text-[4rem] font-normal mb-[2rem] leading-[3.2rem] ">
          Cookie Policy for Delivery Page
        </h2>
        <h2 className="font-rubik text-[2.5rem] font-light mb-[5rem] leading-[3.2rem] ">
          At Delivery Page, we understand the importance of privacy and want to
          be transparent about our use of cookies on our website and in our SaaS
          product. .
        </h2>
      </div>
      <div className="max-w-screen-xl mx-auto mt-[5rem] flex flex-col px-8 md:px-2">
        <p className="font-normal text-[2rem] leading-[4rem] font-rubik mb-8 text-dark">
          Cookies are small text files that are placed on your device when you
          visit our website or use our product. They are used to remember your
          preferences and settings, provide you with a personalized experience,
          and help us understand how you interact with our website and product.
          <br></br>
          We use the following types of cookies:
        </p>
        <p className="font-normal text-[2rem] leading-[4rem] mt-8 font-rubik mb-8 text-dark">
          1. <b>Essential cookies:</b> These cookies are necessary for the proper
          functioning of our website and product. They enable you to navigate
          and use the features and services we offer.
        </p>
        <p className="font-normal text-[2rem] leading-[4rem] mb-8 font-rubik text-dark">
          2. <b>Performance cookies:</b> These cookies collect information about how
          you use our website and product, such as which pages you visit and
          which features you use. This helps us to improve the performance of
          our website and product.
        </p>
        <p className="font-normal text-[2rem] leading-[4rem] mb-8 font-rubik text-dark">
          3. <b>Functional cookies:</b> These cookies allow us to remember your
          preferences and settings, such as your language and location, and
          provide you with a more personalized experience.
        </p>
        <p className="font-normal text-[2rem] leading-[4rem] mb-8 font-rubik text-dark">
          4. <b>Targeting and advertising cookies:</b> These cookies are used to show
          you relevant ads and offers based on your interests and browsing
          history.
        </p>
        <p className="font-normal text-[2rem] leading-[4rem] mb-8 font-rubik text-dark">
          You can control the use of cookies on our website and product by
          adjusting your browser settings. However, disabling cookies may limit
          your ability to use certain features and services. We also use
          third-party services like Google Analytics to analyze the use of our
          website and product. These services may use their own cookies. Please
          refer to their respective privacy policies for more information.
        </p>
        <p className="font-normal text-[2rem] leading-[4rem] mb-[10rem] font-rubik text-dark">
          We also use third-party services like Google Analytics to analyze the
          use of our website and product. These services may use their own
          cookies. Please refer to their respective privacy policies for more
          information.<br></br> By using our website and product, you consent to our use
          of cookies in accordance with this policy. We may update this policy
          from time to time, so please check back regularly to stay informed.
        </p>
      </div>
    </div>
  );
};

export default Cooky;
